/*global dataLayer, poool*/

/** @var {object} Drupal.settings.ae_piwikpro_consent */
/** @var {string} Drupal.settings.ae_piwikpro_consent.privacy_link */

// polyfill
import 'nodelist-foreach-polyfill';

// 3rd party
import $ from 'jquery';

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tooltip';

import fastclick from 'fastclick';
import fitVids from 'fitvids';
import cookie from 'cookie';
import AlterecoCookies from '@insite/altereco_cookies';

// internal
import articleCaption from './modules/article-caption';
import customModal from './modules/custom-modal';
import dossier from './modules/dossier';
import comments from './modules/comments';
import customDataLayer from './modules/data-layer';
import embedPopover from './modules/embed-popover';
import flag from './modules/flag';
import graphicSearch from './modules/graphic-search';
import header from './modules/header';
import kiosk from './modules/kiosk';
import iframeModal from './modules/iframe-modal';
import indicators from './modules/indicators';
import mainSearch from './modules/search';
import lectures from './modules/lectures';
import messages from './modules/messages';
import navigation from './modules/navigation';
import newsletter from './modules/newsletter';
import notes from './modules/notes';
import portfolio from './modules/portfolio';
import print from './modules/print-image';
import social from './modules/social';
import slider from './modules/slider';
import sticker from './modules/sticker';
import twitter from './modules/twitter';
import zoom from './modules/zoom';
import g_opt from './modules/g-opt';
import glossary from './modules/glossary';
import googleSignin from './modules/google-signin';
import appleSignin from './modules/apple-signin';
import copyme from './modules/copy';
import soutenir from './modules/soutenir';
import widgetPromo from './modules/promo-widget';
import giveArticle from './modules/give-article';

import pooolPaywall, {getAccess, pooolPaywallFallback} from './modules/poool';

(function ($) {
  Drupal.behaviors.aemain = {
    attach: function (context, settings) {

      'use strict';
      fastclick(document.body);
      fitVids('body');

      // Bootstrap initialisation
      $('[data-toggle="tooltip"]').tooltip();

      comments();
      customDataLayer();
      dossier();
      embedPopover();
      sticker();
      customModal();
      iframeModal();
      indicators();
      mainSearch();
      social();
      slider();
      kiosk();
      navigation();
      newsletter();
      notes();
      lectures();
      messages();
      zoom();
      header();
      articleCaption();
      twitter();
      print();
      flag();
      graphicSearch();
      portfolio();
      g_opt();
      glossary();
      copyme();
      soutenir();
      widgetPromo();
      giveArticle();
    }
  };
}(jQuery));

/**
 * @param subscriptionStatus
 * @param {string} abTestValue
 */
function startPoool(subscriptionStatus, abTestValue = '') {
  // Paywall complet.
  if (typeof poool === 'function') {
    pooolPaywall(subscriptionStatus, abTestValue);
  }
}

export function getSubscriptionStatus() {
  'use strict';
  const documentCookie = cookie.parse(document.cookie);

  let subscriptionStatus = 'no_subscription';

  if (documentCookie.hasOwnProperty('subscription_status')) {
    subscriptionStatus = JSON.parse(documentCookie.subscription_status);
  }

  return subscriptionStatus;
}

window.bindGoogleSignin = googleSignin;
window.bindAppleSignin = appleSignin;
window.getAccess = getAccess;
window.getSubscriptionStatus = getSubscriptionStatus;
window.pooolPaywallFallback = pooolPaywallFallback;
window.startPoool = startPoool;

$(document).ready(function () {
  'use strict';
  // GA TRACKING
  const documentCookie = cookie.parse(document.cookie);

  let userLogged = false;
  let subscriptionId = 0;

  if (documentCookie.hasOwnProperty('logged_in')) {
    userLogged = true;
  }

  if (documentCookie.hasOwnProperty('subscription_id')) {
    subscriptionId = JSON.parse(documentCookie.subscription_id);
  }

  window.dataLayer = window.dataLayer || [];

  dataLayer.push({
    'visitorIsLogged': userLogged,
    'event': 'visitorLogged'
  });
  // END GA TRACKING

  if (typeof Drupal.settings.ae_piwikpro_consent != 'undefined') {
    AlterecoCookies.init(
      Drupal.settings.ae_piwikpro_consent.privacy_link,
      jQuery('#ppms_cm_privacy_settings_button').length === 0
    );
  }
});

// Ceci permet de déclencher un événement sas_loaded quand PiwikPRO a fini de
// charger SmartAdServer.
// @see
// https://stackoverflow.com/questions/7307983/while-variable-is-not-defined-wait
Object.defineProperty(window, 'sas', {
  configurable: true,
  set(v) {
    Object.defineProperty(window, 'sas', {
      configurable: true, enumerable: true, writable: true, value: v
    });
    Object.defineProperty(sas, 'configure', {
      configurable: true,
      set(v) {
        Object.defineProperty(sas, 'configure', {
          configurable: true, enumerable: true, writable: true, value: v
        });
        window.dispatchEvent(new Event('sas_loaded'));
      }
    });
  }
});
