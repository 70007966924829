import {utils} from "@insite/utils";
import $ from "jquery";
import {getSubscriptionStatus} from "../main";

class ScrollProgress {

  /**
   * @constructor
   * @param {HTMLElement} post
   * @param {Object} options
   */
  constructor(post, options = {}) {

    this.options = Object.assign({}, {
      class: 'progress',
      topShift: 250,
      bottomShift: 120,
      destination: this.post,
      finishCallback: null
    }, options);

    this.post = post
    this.inViewport = this.finish = this.callbackFired = false

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => this.inViewport = entry.isIntersecting)
    });

    this.observer.observe(post);

    this.progressBar = utils.createDivWithClass(this.options.class)
    this.progressBarInner = utils.createDivWithClass(this.options.class + '__inner')
    this.progressBar.append(this.progressBarInner)
    if (this.options.destination !== this.post) {
      document.querySelector(this.options.destination).append(this.progressBar)
    }
    else {
      this.post.append(this.progressBar)
    }

    let timeout;

    window.onscroll = () => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      timeout = window.requestAnimationFrame(() => {
        this.showReadingProgress(this);
      });
    }
  }

  getScrollProgress(el, that) {
    let progressPercentage = 0;

    if (that.inViewport) {
      let coords = el.getBoundingClientRect();
      let coordsTop = coords.top - this.options.topShift;

      // On ajoute à la hauteur de l'element le topShift et on lui retire le
      // bottomShift pour que le pourcentage soit bien calculé en fonction des
      // deux décalages
      let height = coords.height + this.options.topShift - this.options.bottomShift;

      if (coordsTop < 0) {
        progressPercentage = (Math.abs(coordsTop) / height) * 100;
      }

      if (progressPercentage > 100) {
        this.finish = true;
        this.progressBar.classList.add('full')
        this.post.classList.add('finished')
        document.body.classList.add('full-read');

        if (typeof this.options.finishCallback == 'function' && !this.callbackFired) {
          this.options.finishCallback();
          this.callbackFired = true;
        }

      }
      else {
        this.finish = false;
        this.progressBar.classList.remove('full')
        this.post.classList.remove('finished')
        document.body.classList.remove('full-read');
      }
    }
    return progressPercentage;
  }

  showReadingProgress(that) {
    that.progressBarInner.setAttribute("style", `width: ${that.getScrollProgress(this.post, that)}%`);
  }

}


/**
 * @type {ScrollProgress}
 */
let progress;

/**
 * @type {boolean}
 */
let updating = false;

/**
 * @type {number}
 */
let currentProgress = 0;
let lastProgressChange = 0;
let ProgressOnLastSaveTry = 0;
let lastSaveFailed = false;
let target = 0;
let scrollStepHeight = 1000;
let lastScrollDirection = 'down';

/**
 * @return {void}
 */
function finish() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'onContentRead'
  });

  if (jQuery('.o-view-more')) {
    jQuery('.o-view-more').append('<span class="c-custom-modal__close">\n' +
        '        <a href="#" class="icon--close js-close"></a>\n' +
        '      </span>')
    jQuery('.o-view-more .js-close').on('click', function () {
      jQuery('body').removeClass('full-read');
      return (false);
    });
  }

  // Si on est sur la page d'un node en mode connecté, on tracke dans Mixpanel
  // le fait que le contenu a été lu.
  if (Drupal.settings.ae_mixpanel_node_view && Drupal.settings.ae_article_display_node_view_alter && Drupal.settings.ae_article_display_node_view_alter.uid > 0) {
    // getSubscriptionStatus() n'est normalement utilisable que lorsque l'appel
    // AJAX qui traite les abos multiposte est fini. Ici, on peut supposer que
    // si l'internaute a eu le temps de scroller jusqu'en bas, l'AJAX est fini.
    mixpanel_track('content_read', Object.assign({subscription_status: getSubscriptionStatus()}, Drupal.settings.ae_mixpanel_node_view));
  }
}

function getCurrentNodeId() {
  const $body = $('body.page-node');
  if (!$body.length) {
    return false;
  }
  const bodyClasses = $body.attr('class').split(/\s+/);
  for (let i in bodyClasses) {
    const c = bodyClasses[i];
    if (c.length > 10 && c.substring(0, 10) === 'page-node-') {
      return parseInt(c.substring(10), 10);
    }
  }
  return false;
}

function userIsAnonymous() {
  return $('body.not-logged-in').length;
}


function saveReadProgression(newProgress) {
  if (userIsAnonymous()) {
    return;
  }

  // En-dessous de 20%, on ne considère pas que le pourcentage lu vaut la peine
  // d'être enregistré.
  if (newProgress < 20) {
    return;
  }

  // Si on a scrollé assez depuis le dernier déclenchement de sauvegarde, on
  // veut forcément sauvegarder à nouveau.
  // On veut sauvegarder uniquement tous les 5%, sinon on envoie plein de
  // requêtes pour rien.
  // Sinon, on veut quand même sauvegarder si jamais la précédente tentative
  // a échoué.
  if (newProgress <= (ProgressOnLastSaveTry + 5) && !lastSaveFailed) {
    return;
  }


  ProgressOnLastSaveTry = newProgress;
  $.ajax({
    url: '/ajax/read-progression/set/' + getCurrentNodeId() + '/' + newProgress,
    type: 'get',
  })
      .done(function () {
        lastSaveFailed = false;
      })
      // Si ceci ne fonctionne pas, ce n'est pas dramatique, on ne va pas tout
      // bloquer.
      .fail(function (jqXHR, textStatus) {
        console.log('Impossible d\'enregistrer la progression de lecture : ' + textStatus);
        lastSaveFailed = true;
      });
}

function getCurrentProgress() {
  let float = progress.getScrollProgress(progress.post, progress);
  return parseInt(float);
}

/**
 * Recursive function : scroll a bit in the right direction if the scroll is
 * not yet at the target place.
 */
function scrollTowardKnownProgress() {
  let current = getCurrentProgress();
  if (current < target) {
    // Il faut scroller vers le bas.
    if (lastScrollDirection !== 'down') {
      // On est en train de descendre alors qu'on montait, on ne veut pas
      // descendre d'autant.
      scrollStepHeight = Math.max(1, scrollStepHeight / 2);
      lastScrollDirection = 'down';
    }
    window.scrollBy(0, scrollStepHeight);
  }
  else if (current > target) {
    // Il faut scroller vers le haut.
    if (lastScrollDirection !== 'up') {
      // On est en train de monter alors qu'on descendait, on ne veut pas
      // monter d'autant.
      scrollStepHeight = Math.max(1, scrollStepHeight / 2);
      lastScrollDirection = 'up';
    }
    window.scrollBy(0, -scrollStepHeight);
  }
  else {
    // current = target, pas besoin de continuer à scroller.
    return;
  }
  // On programme un nouveau scroll, une fois que le rendu aura été mis à jour.
  requestAnimationFrame(scrollTowardKnownProgress);
}

function scrollToKnownProgress() {
  // On cherche si l'article est déjà en cours de lecture, et si oui, on
  // scrolle à la bonne hauteur.

  if (userIsAnonymous()) {
    return;
  }

  $.ajax({
    url: '/ajax/read-progression/get/' + getCurrentNodeId(),
    type: 'get',
  })
      .done(function (percents) {
        target = Math.min(100, Math.max(0, percents));
        scrollTowardKnownProgress();
      })
      // Si ceci ne fonctionne pas, ce n'est pas dramatique, on ne va pas tout
      // bloquer.
      .fail(function (jqXHR, textStatus) {
        console.log('Impossible de récupérer la progression de lecture : ' + textStatus);
      });
}


/**
 * @return {void}
 */
function updateProgress() {
  if (typeof (progress) === 'undefined') {
    return;
  }

  let newProgress = getCurrentProgress();

  if (newProgress > 100) {
    newProgress = 100;
  }

  // Pas besoin de mettre le DOM à jour si le pourcentage n'a pas changé.
  if (newProgress > 0 && newProgress !== currentProgress) {
    jQuery('.progress__inner').html('<span>Lu à ' + newProgress + ' %</span>');
    currentProgress = newProgress;
  }

  if (newProgress < currentProgress) {
    jQuery('article.o-page').removeClass('readed');
  }

  saveReadProgression(newProgress);

  updating = false;
}


/**
 * @return {void}
 */
function onScroll() {
  if (!updating) {
    // Pour ne pas actualiser à une fréquence inutilement élevée.
    updating = true;
    requestAnimationFrame(updateProgress);
  }
}


/**
 * @return {void}
 */
export default () => {
  /** @var {HTMLElement} body  */
  const body = document.querySelector('.o-page__content__body');

  if (body
    // Pas de suivi de la progression sur les quiz
    && jQuery('div.typeform-widget, div[data-tf-widget]').length === 0
  ) {
    if (jQuery('.o-view-more')) {
      jQuery('.o-view-more__content').once('wrap').wrap('<div class="o-view-more__overflow"></div>');
    }

    jQuery('#header').append('<div id="reading-progress" class="o-zone">' +
        '<div id="reading-progress__inner" class="o-page">' +
        '</div>' +
        '</div>');
    progress = new ScrollProgress(body, {
      'class': 'progress',
      'destination': '#reading-progress__inner',
      'bottomShift': window.innerHeight / 2,
      'finishCallback': finish
    });

    if (jQuery('#header-wrapper').length > 0) {
      var hPub = parseInt(jQuery('#header-wrapper > .o-zone').height());
      var prog = jQuery('#reading-progress #reading-progress__inner');
      var more = jQuery('.o-view-more');

      prog.css('top', parseInt(prog.css('top')) + hPub + 'px');
      more.css('margin-top', hPub + 'px');
    }

    jQuery(window).scroll(onScroll);

    scrollToKnownProgress();
  }
};
