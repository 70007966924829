/* global mixpanel */

import $ from 'jquery';

var Flag = {
  create: function() {
    var instance = Object.assign({}, this.prototype);

    this.init.apply(instance, arguments);

    return instance;
  },

  init: function(elem) {
    this.$elem = $(elem);

    this.$elem.tooltip({
      trigger: 'hover',
      title: 'This changes'
    });

    this.tooltip = this.$elem.data('bs.tooltip');

    this.setLoaded();

    if (this.$elem.attr('data-ae-flag-status') === 'flagged') {
      this.$elem.attr('data-ae-flag-status', 'flagged')
        .removeClass('icon--starborder')
        .addClass('icon--starfilled');

      this.updateTooltip('Supprimer de mes lectures');
    }
    else {
      this.$elem.attr('data-ae-flag-status', 'unflagged')
        .removeClass('icon--starfilled')
        .addClass('icon--starborder');

      this.updateTooltip('Ajouter à mes lectures');
    }

    this.$elem.on('click', function(e) {
      e.preventDefault();

      this.toggleFlag();
    }.bind(this));
  },

  prototype: {
    updateTooltip: function(newTitle) {
      this.tooltip.config.title = newTitle;
    },

    setLoading: function() {
      this.state = 'loading';
      this.$elem.addClass('is-loading');
    },

    setLoaded: function() {
      this.state = 'loaded';
      this.$elem.removeClass('is-loading');
    },

    isLoading: function() {
      return this.state === 'loading';
    },

    setFlagged: function() {
      this.$elem.attr('data-ae-flag-status', 'flagged')
        .removeClass('icon--starborder')
        .addClass('icon--starfilled');

      this.updateTooltip('Lecture ajoutée');
      this.$elem.tooltip('show');

      window.setTimeout(function() {
        this.$elem.tooltip('hide');
        this.updateTooltip('Supprimer de mes lectures');
      }.bind(this), 1000);

      // On tracke seulement si l'internaute est identifié par son email.
      if (/^.+@.+\..+$/.test(mixpanel.get_distinct_id())) {
        mixpanel_track('bookmark', Drupal.settings.ae_mixpanel_node_view);
      }
    },

    setUnflagged: function() {
      this.$elem.attr('data-ae-flag-status', 'unflagged')
        .removeClass('icon--starfilled')
        .addClass('icon--starborder');

      this.updateTooltip('Lecture supprimée');
      this.$elem.tooltip('show');

      window.setTimeout(function() {
        this.$elem.tooltip('hide');
        this.updateTooltip('Ajouter à mes lectures');
      }.bind(this), 1000);
    },

    toggleFlag: function() {
      if (this.isLoading()) {
        return false;
      }

      this.setLoading();

      $.ajax({
        type: 'POST',
        url: this.$elem.attr('href'),
        data: { js: true },
        dataType: 'json',
        success: function (data) {
          var parser = new window.DOMParser();
          var doc = parser.parseFromString(data.newLink, 'text/html');

          var link = doc.querySelector('a');

          this.$elem.attr('href', link.getAttribute('href'));

          if (data.flagStatus === 'flagged') {
            this.setFlagged();
          }
          else {
            this.setUnflagged();
          }

          this.setLoaded();
        }.bind(this),
        error: function (xmlhttp) {
          console.error('An HTTP error '+ xmlhttp.status +' occurred.');

          this.setLoaded();
        }.bind(this)
      });
    }
  }
};

export default () => {
  var $flags = $('[data-ae-flag]');

  $flags.each(function(index, elem) {
    try {
      Flag.create(elem);
    } catch(e) {
      console.error(e);
    }
  });
};
