import $ from 'jquery';

const attrName = 'data-ae-popover';

export default () => {
  $(`[${attrName}]`).each((index, elem) => {
    const $elem = $(elem);

    const url = $(elem).attr(attrName);
    const target = $(elem).attr(`${attrName}-target`);

    $elem.on('click', e => e.preventDefault());

    const height = $elem.closest(target).height() ? $elem.closest(target).height() : 500;

    const markup = `<textarea type="text" rows="6"><iframe width="684" height="${height}" frameborder="0" src="${url}"></iframe></textarea>`;

    $elem.popover({
      container: 'body',
      content: markup,
      html: true,
      title: 'Copier ce code sur votre site ou blog',
      template: '<div class="popover ae-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });

    $elem.on('show.bs.popover', function() {
      $elem.addClass('popover-active');
    });

    $elem.on('hidden.bs.popover', function() {
      $elem.removeClass('popover-active');
    });
  });
};
