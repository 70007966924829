function isChild (obj,parentObj){
  while (obj != undefined && obj != null && obj.tagName.toUpperCase() != 'BODY'){
    if (obj == parentObj){
      return true;
    }
    obj = obj.parentNode;
  }
  return false;
}

class Dropdown {

  /**
   * @constructor
   * @param {InnerHTML} content
   * @param {Object} options
   */
  constructor(dropdown, options = {}) {

    this.dropdown = dropdown;
    this.options = Object.assign({}, {
      ariaLabel: 'Navigation',
      checkPos: false,
      freezeScroll: true,
      clickCallback: null,
      finishCallback: null
    }, options)

    this.isOpen = false;

    this.finishCallbackFired = false

    this.button = dropdown.querySelector('button');
    this.dropdownContent = dropdown.querySelector('.give-article__content');


    // On bind le click burger
    this.button.addEventListener("click", (e) => {

      e.preventDefault();

      this.button.setAttribute('aria-expanded', !this.isOpen);
      this.dropdownContent.setAttribute('aria-hidden', this.isOpen);

      this.dropdown.setAttribute('data-open', !this.isOpen);

      if (this.options.checkPos) {
        this.dropdown.setAttribute('data-x', this.checkPos(this.button)[0]);
        this.dropdown.setAttribute('data-y', this.checkPos(this.button)[1]);
      }

      this.isOpen = !this.isOpen;

      let that = this;

      this.button.addEventListener('focusout', (e) => {
        setTimeout(() => {
          // SI le focus se place pas sur un enfant on ferme
          if (!isChild(document.activeElement, that.dropdown)) {
            that.button.setAttribute('aria-expanded', false);
            that.dropdownContent.setAttribute('aria-hidden', true);
            that.dropdown.setAttribute('data-open', false);
            that.isOpen = false;
          } else {
            document.addEventListener('focusin', function(e) {
              if(!isChild(e.target, that.dropdown)){
                that.button.setAttribute('aria-expanded', false);
                that.dropdownContent.setAttribute('aria-hidden', true);
                that.dropdown.setAttribute('data-open', false);
                that.isOpen = false;
              }
            })
          }

        },200);

      });

    });

  }

  /**
   * @method checkPos
   * @param {Object} elem
   */
  checkPos(elem) {

    let xY = [];

    let rect = elem.getBoundingClientRect();
    let wH = window.innerHeight;
    let wW = window.innerWidth;
    let wY = window.scrollY;
    let leftByCenter = rect.left + rect.width / 2;
    let topByCenter = rect.top + rect.height / 2;

    if(leftByCenter < wW * .333) {
      xY.push('X-left');
    }
    if(leftByCenter > wW * .666) {
      xY.push('X-right');
    }
    if(leftByCenter >= wW * .333 && leftByCenter <= wW * .666){
      xY.push('X-center');
    }

    if(topByCenter < wH * .333) {
      xY.push('Y-top');
    }
    if(topByCenter > wH * .666) {
      xY.push('Y-bottom');
    }
    if(topByCenter >= wH * .333 && topByCenter <= wH * .666){
      xY.push('Y-center');
    }
    return xY;
  }
}

export default () => {

  if ($('.give-article').once('scroll').length) {
    document.querySelectorAll('.give-article').forEach(element => {
      new Dropdown(element, {
        checkPos: true
      });
    });
  }

};


