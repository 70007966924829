import $ from 'jquery';

export default () => {

  if ($('.block__donate').length > 0) {
    $(document).on('click', '.block__donate a', function () {
      $('html,body').animate({
        scrollTop: $('.frame__donate').offset().top
      }, 'slow');
      return false;
    });
  }
}
