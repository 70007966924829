import $ from 'jquery';

import randomID from 'random-id';

const $window = $(window);

const CONSTANTS = {
  attrName: 'data-ae-iframe-modal'
};

function getTpl({ id, title, height, source = '', technical = '' }) {
  if (source.length > 0) {
    source = `
    <div class="c-bloc-medias__footer">
      <div class="c-bloc-medias__source">
        <span>Source : ${source}</span>
      </div>
    </div>`;
  }

  if (technical.length > 0) {
    technical = `
      <span class="c-bloc-medias__subtitle">${technical}</span>
    `;
  }

  const tpl =
  `<div id="${id}" tabindex="-1" role="dialog" aria-hidden="true" class="c-modal c-modal--wide fade">
      <div role="document" class="c-modal__dialog modal-dialog">
        <div class="c-modal__content c-modal__content--dark">
          <div class="c-modal__content__body">
            <button type="button" data-ae-iframe-modal-close aria-label="Close" class="c-modal__close"><span aria-hidden="true">×</span></button>
              <article class="c-bloc-medias">
                <div class="c-bloc-medias__inner">
                  <div class="c-bloc-medias__header">
                    <h3 class="c-bloc-medias__title">${title}</h3>
                    ${technical}
                  </div>
                  <div class="c-bloc-medias__body">
                    <div class="c-bloc-medias__figure">
                      <div class="c-bloc-medias__iframewrapper">
                        <iframe height="${height}"></iframe>
                      </div>
                    </div>
                  </div>
                  ${source}
                </div>
              </article>
          </div>
        </div>
      </div>
  </div>`;

  return tpl;
}

class IframeModal {
  constructor(trigger) {
    this.$trigger = $(trigger);

    this.title = this.$trigger.attr(`${CONSTANTS.attrName}-title`);
    this.url = this.$trigger.attr(`${CONSTANTS.attrName}-url`);
    this.technical = this.$trigger.attr(`${CONSTANTS.attrName}-technical`);
    this.source = this.$trigger.attr(`${CONSTANTS.attrName}-source`);
    this.height = this.$trigger.attr(`${CONSTANTS.attrName}-height`);

    this.height = this.height ? this.height : 450;

    this.id = this.title ? this.title.replace(/ /g, '-').toLowerCase() : randomID(20, 'a');

    let iframe = `<iframe height="${this.height}" src="${this.url}"></iframe>`;

    this.$modal = $(
      getTpl({
        id: this.id,
        title: this.title,
        technical: this.technical,
        source: this.source,
        height: this.height
      })
    );

    this.$modal.appendTo(document.body);

    this.$modal.on('click', `[${CONSTANTS.attrName}-close]`, e => {
      e.preventDefault();

      this.$modal.modal('hide');
    });

    this.savedScroll = 0;

    this.$trigger.on('click', e => {
      e.preventDefault();

      this.savedScroll = $window.scrollTop();
      this.$modal.modal('show');
    });

    this.iframeLoaded = false;

    this.$modal.on('shown.bs.modal', e => {
      if (!this.iframeLoaded) {
        this.$modal.find('.c-bloc-medias__iframewrapper').html(iframe);

        this.iframeLoaded = true;
      }
    });

    this.$modal.on('hidden.bs.modal', e => {
      $window.scrollTop(this.savedScroll);
    });
  }
}

export default () => {
  $(`[${CONSTANTS.attrName}]`).each(function(i, elem) {
    const modal = new IframeModal(elem);
  });
};
