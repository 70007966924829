import $ from 'jquery';

export default () => {
  if ($('[data-ae-iframe]').length > 0) {
    $.getScript('https://cdn.jsdelivr.net/npm/iframe-resizer@3.6.2/js/iframeResizer.min.js', function() {
      iFrameResize({
        log: true,
        checkOrigin: ['https://data.alternatives-economiques.fr']
      });
    });
  }

  if ($('[data-ae-parallax]').length > 0) {
    $.getScript('https://cdnjs.cloudflare.com/ajax/libs/object-fit-images/3.2.4/ofi.min.js', function() {
      objectFitImages($('.c-portfolio-item__parallax-image'));

      $.getScript('https://cdnjs.cloudflare.com/ajax/libs/jarallax/1.10.5/jarallax.min.js', function() {
        $('[data-ae-parallax]').jarallax({
          speed: 0.2,
          imgElement: '.c-portfolio-item__parallax-image'
        });
      });
    });
  }
};
