import $ from 'jquery';

import 'slick-carousel/slick/slick.min';

export default () => {

  var lectures = $('.js-lectures');

  var _slider = function(element, index) {

    var slider = '#lectures-' + index;

    var lecturesPrev = slider + ' .js-lectures-prev';
    var lecturesNext = slider + ' .js-lectures-next';
    var lecturesDots = slider + ' .js-lectures-dots';

    $(element).slick({
      prevArrow: lecturesPrev,
      nextArrow: lecturesNext,
      dots: true,
      appendDots: lecturesDots,
      autoplay: true,
      autoplaySpeed: 4000
    });
  };

  $.each(lectures, function(i, val) {
    $(val).parent().attr('id', 'lectures-' + i);
    _slider(this, i);
  });
};
