import $ from 'jquery';
import Select from '@alter-eco/select';

function getPreselected(data) {
  return Object.keys(data).filter(function(key) {
    var item = data[key];

    if (item.checked) {
      return item;
    }
  });
}

export default () => {
  var $graphicSearchForm = $('[data-graphic-search]');

  if (!$graphicSearchForm.length) {
    return false;
  }

  $(document.body).addClass('has-graphic-search');

  var $checkbox = $('#edit-term-node-tid-depth-wrapper .bef-tree .form-type-bef-checkbox');

  var data = {};

  var name = $checkbox.find('input[type="checkbox"]')[0].name;

  var dataLevelOne = $checkbox.each(function(index, elem) {
    if (!elem.closest('.bef-tree-depth-1')) {
      var input = elem.children[0];
      var label = elem.children[1];

      data[input.value] = {
        value: input.value,
        label: label.innerText,
        checked: input.checked,
        children: {}
      };

      return elem;
    }
  });

  dataLevelOne.each(function(index, elem) {
    var $parentListElement = $(elem.parentElement.parentElement);

    var parentValue = elem.children[0].value;

    var $checkboxFields = $parentListElement.find('.bef-tree-depth-1 .form-type-bef-checkbox');

    $checkboxFields.each(function(index, elem) {
      var input = elem.children[0];
      var label = elem.children[1];

      data[parentValue].children[input.value] = {
        value: input.value,
        label: label.innerText,
        checked: input.checked
      };
    });
  });

  var firstSelectOptions = Object.keys(data).map(function(key) {
    return data[key];
  });

  var selectFieldWrapper = document.querySelector('#edit-term-node-tid-depth-wrapper .c-graphic-search__item__field');

  selectFieldWrapper.innerHTML = '';

  var firstSelect = Select.create({
    name: name,
    placeholder: 'Choisir une thematique',
    options: firstSelectOptions
  });

  firstSelect.appendTo(selectFieldWrapper);

  var firstSelectValue;
  var secondSelect;

  var preSelected = getPreselected(data);
  var firstRun = true;

  firstSelect.on('change', function(event, value) {
    if (firstSelectValue && firstSelectValue !== value && secondSelect) {
      secondSelect.destroy();
      secondSelect = null;
    }

    firstSelectValue = value;

    if (!secondSelect && !$.isEmptyObject(data[value].children)) {

      var secondSelectOptions = Object.keys(data[value].children).map(function(key) {
        return data[value].children[key];
      });

      secondSelect = Select.create({
        name: name,
        placeholder: 'Choisir une sous-thematique',
        options: secondSelectOptions
      });

      secondSelect.appendTo(selectFieldWrapper);

      if (firstRun) {
        var preSelected2 = getPreselected(data[value].children);

        if (preSelected2.length > 0) {
          secondSelect.update(data[value].children[preSelected2]);
        } else {
          secondSelect.update('');
        }

        firstRun = false;
      }
    }
  });

  if (preSelected.length > 0) {
    firstSelect.update(data[preSelected]);
  } else {
    firstSelect.update('');
  }
};
