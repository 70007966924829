import $ from 'jquery';
import * as _ from '../_';

function handleScrollDesktopHeader($wrapper) {
  var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var scrollClass = 'is-sticky';

  if (currentScrollTop > 150) {
    $wrapper.addClass(scrollClass);
  } else {
    $wrapper.removeClass(scrollClass);
  }
}

function handleScrollMobileMenu($menuMobile) {
  var scrollClass = 'is-scroll-up';
  var lastScrollTop = handleScrollMobileMenu.lastScrollTop || 0;
  var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var isScrolled = handleScrollMobileMenu.isScrolled || false;

  if (currentScrollTop <= lastScrollTop || currentScrollTop <= 100) {
    if (!isScrolled) {
      $menuMobile.addClass(scrollClass);
    }

    isScrolled = true;
  } else {
    if (isScrolled) {
      $menuMobile.removeClass(scrollClass);
    }

    isScrolled = false;
  }

  handleScrollMobileMenu.isScrolled = isScrolled;
  handleScrollMobileMenu.lastScrollTop = currentScrollTop;
}

function handleScrollMobileHeader() {
  const body = document.body;
  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 0;
  let lastScrollTimed = 50;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      body.classList.remove(scrollDown);
      return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      body.classList.add(scrollDown);
      if(currentScroll < lastScrollTimed) {
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
    }
    lastScroll = currentScroll;
    lastScrollTimed = lastScroll - 25;
  });
}

export default () => {

  var $wrapper = $('#header-wrapper');
  var $header = $('#header');
  var $menuMobile = $('#menu-mobile');

  if (!$header.length) {
    return false;
  }

  window.addEventListener('scroll', _.throttle(handleScrollDesktopHeader.bind(this, $wrapper), 100));
  window.addEventListener('scroll', _.debounce(handleScrollMobileMenu.bind(this, $menuMobile), 150));

  handleScrollDesktopHeader($wrapper);
  handleScrollMobileHeader();
  handleScrollMobileMenu($menuMobile);
};
