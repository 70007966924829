import $ from 'jquery';

export default () => {
  $(document).on({
    mouseenter: function () {
      $(this).addClass('hover');
      $(".view-views-glossary-example .view-content .views-summary a").not(this).addClass("inactive");
    },
    mouseleave: function () {
      $(this).removeClass("hover");
      $(".view-views-glossary-example .view-content .views-summary a").removeClass("inactive");
    }
  }, ".view-views-glossary-example .view-content .views-summary a"); //pass the element as an argument to .on

}
