import $ from 'jquery';

function scrollTo($container, $elem) {
  $container.animate({
    scrollTop: $elem.offset().top - $container.offset().top + $container.scrollTop()
  });
}

export default () => {
  const $comments = $('#comments');

  if ($comments.length > 0) {
    const $commentsContainer = $comments.find('.c-custom-modal__content');
    const $commentsForm = $comments.find('.comment-form');
    const $commentsFormLabel = $commentsForm.find('label[for="comments-text"]');

    const defaultAction = $commentsForm.attr('action');
    const defaultLabel = $commentsFormLabel.html();

    const $cancelButton = $('<button class="c-btn" data-ae-comment-cancel>Annuler</button>');

    $commentsFormLabel.after($cancelButton);

    $cancelButton.hide();

    const hash = window.location.hash;

    if (hash.indexOf('comment') !== -1) {
      const $triggers = $('[data-custom-modal="#comments"]');

      $triggers.get(0).click();

      scrollTo($commentsContainer, $(hash));
    }

    $cancelButton.on('click', e => {
      e.preventDefault();

      $commentsFormLabel.html(defaultLabel);
      $commentsForm.attr('action', defaultAction);

      $cancelButton.hide();
    });

    $comments.on('click', '[data-ae-comment]', e => {
      e.preventDefault();

      const $elem = $(e.currentTarget);

      $commentsFormLabel.html(`Répondre à ${$elem.data('aeComment')}`);

      $cancelButton.show();

      $commentsForm.attr('action', $elem.attr('href'));

      scrollTo($commentsContainer, $commentsForm);

      $commentsForm.find('textarea').focus();
    });
  }
};
