import $ from 'jquery';

export default () => {
  const visibleClass = 'is-hidden';
  const $carousel = $('[data-dossier-carousel]');

  if ($carousel.length != 1) {
    return false;
  }

  const $intro = $('[data-dossier-intro]');
  const $nav = $('[data-dossier-navigation]');

  $carousel.data('currentSlide', 0);

  $carousel.data('slideCount', $carousel.find('.c-dossier__carousel__item').length);

  $intro.on('click', '.c-btn', function(e) {
    e.preventDefault();

    hideIntro();

    changeSlide($carousel.data('currentSlide'));

    showCarousel();
  });

  function handleDossierNavClick(e) {
    e.preventDefault();

    const goTo = e.currentTarget.dataset.dossierNavigationItem;

    if (goTo === 'intro') {
      showIntro();
      hideCarousel();
      changeSlide(0);
    } else if (+goTo) {
      hideIntro();
      showCarousel();
      changeSlide(+goTo - 1);
    }
  }

  $nav.on('click', '[data-dossier-navigation-item]', handleDossierNavClick);
  $carousel.on('click', '[data-dossier-navigation-item]', handleDossierNavClick);

  $carousel.on('click', '[data-dossier-carousel-nav]', function(e) {
    e.preventDefault();

    const direction = e.currentTarget.dataset.dossierCarouselNav;

    if (direction === 'prev' && $carousel.data('currentSlide') > 0) {
      changeSlide($carousel.data('currentSlide') - 1);
    } else if (direction === 'next' && $carousel.data('currentSlide') !== $carousel.data('slideCount') - 1) {
      changeSlide($carousel.data('currentSlide') + 1);
    } else if (direction === 'prev' && $carousel.data('currentSlide') === 0) {
      hideCarousel();
      showIntro();
    }
  });

  function changeSlide(slideNumber) {
    const translate = slideNumber * 100;

    $carousel.find('.c-dossier__carousel__items').css('transform', 'translateX(-' + translate + '%)');

    $carousel.data('currentSlide', slideNumber);

    removeActiveLinks();

    $nav.find('.c-dossier__navigation__item:nth-child(' + (slideNumber + 2) + ')').addClass('active');
    $carousel.find('.c-dossier__carousel__nav__item:nth-child(' + (slideNumber + 1) + ')').addClass('active');

    if (slideNumber === $carousel.data('slideCount') - 1) {
      enableButtons();
      disableButton($carousel.find('[data-dossier-carousel-nav="next"]'));
    } else {
      enableButtons();
    }
  }

  function disableButton($button) {
    $button.attr('disabled', '');
  }

  function enableButtons() {
    $carousel.find('[data-dossier-carousel-nav]').removeAttr('disabled');
  }

  function removeActiveLinks() {
    $nav.find('.c-dossier__navigation__item').removeClass('active');
    $carousel.find('.c-dossier__carousel__nav__item').removeClass('active');
  }

  function showIntro() {
    $intro.removeClass(visibleClass);
  }

  function hideIntro() {
    $intro.addClass(visibleClass);
  }

  function showCarousel() {
    $carousel.removeClass(visibleClass);
  }

  function hideCarousel() {
    $carousel.addClass(visibleClass);
  }
};
