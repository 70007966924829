import $ from 'jquery';

export default () => {
  // Sur document parce que le bandeau peut apparaitre plus tard.
  $(document).ready(function(){
    console.log('promo');
    $('#promo-widget').append('<span class="widget-close icon--close"></span>');
  });
  $(document).on('click', '#promo-widget .widget-close', function(e) {
    e.preventDefault();
    $(this).parent('#promo-widget').addClass('is-hidden');
  });
};
