import $ from 'jquery';

import 'slick-carousel/slick/slick.min';

export default () => {

  var kiosk = $('.js-kiosk');

  var _slider = function(element, index) {

    var slider = '#kiosk-' + index;

    var kioskPrev = slider + ' .js-kiosk-prev';
    var kioskNext = slider + ' .js-kiosk-next';
    var kioskDots = slider + ' .js-kiosk-dots';

    $(element).slick({
      prevArrow: kioskPrev,
      nextArrow: kioskNext,
      dots: true,
      appendDots: kioskDots,
      autoplay: true,
      autoplaySpeed: 4000
    });
  };

  $.each(kiosk, function(i, val) {
    $(val).parent().attr('id', 'kiosk-' + i);
    _slider(this, i);
  });
};
