import $ from 'jquery';

export default () => {
  var $zoomTrigger = $('.js-zoom');

  $zoomTrigger.on('click', function(e) {
    e.preventDefault();

    $(this).toggleClass('is-active');
  });
};
