import $ from 'jquery';

export default () => {
  var $body = $('body');

  $body.on('click', '.js-sticker-close', function(e) {
    e.preventDefault();

    $(e.target.parentElement).addClass('is-hidden');
  });
};
