import $ from 'jquery';

var PrintImage = {
  create: function() {
    var instance = $.extend({}, this.prototype);

    this.init.apply(instance, arguments);

    return instance;
  },

  init: function(elem) {

    this.$elem = $(elem);

    this.url = this.$elem.attr('data-ae-print-image');

    this.$elem.on('click', function(e) {

      e.preventDefault();

      this.printAction();

    }.bind(this));
  },

  prototype: {

    printAction: function() {

      var win = window.open('');

      win.document.write('<img src="' + this.url + '" onload="window.print();window.close()" />');

      win.focus();

      return win;

    }
  }
};

export default () => {
  $('[data-ae-print-image]').each(function(index, elem) {
    PrintImage.create(elem);
  });
};
