import $ from 'jquery';

export default () => {
  if ($('ul.footnotes')) {
    var notes = $('ul.footnotes');

    $('ul.footnotes').remove();

    $('.c-supportus').parent('.o-page__footer__item').before(notes);
    notes.wrap('<div class="o-page__footer__item"></div>')
  }
}
