import $ from 'jquery';

import 'slick-carousel/slick/slick.min';

function slider(elem) {
  var $elem = $(elem);

  var selector = $elem.data('aeSlider');

  $elem.find(selector).slick({
    arrows: false,
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000
  });
}

export default () => {
  $('[data-ae-slider]').each(function(index, elem) {
    slider(elem);
  });
};
