import $ from 'jquery';

const className = '.js-messages';

export default () => {
  const $msgContainer = $(className);

  $msgContainer.on('click', `${className}-close`, function(e) {
    e.preventDefault();
    $(this).closest(className).addClass('is-hidden');
  });
};
