/* global Drupal*/

/**
 * L'utilisateur s'est authentifié via Apple, on a récupéré un ID token qu'il
 * faut maintenant vérifier, dont il faut extraire l'email afin de pouvoir
 * ensuite loguer le compte Drupal.
 */
function onSignin(event) {
  const token = event.detail.authorization.id_token;

  window.location = Drupal.settings.basePath + 'apple_signin/' + token + '?' + jQuery.param({ destination: window.location.pathname });
}

export default () => {
  // Listen for authorization success.
  document.addEventListener('AppleIDSignInOnSuccess', onSignin);
};
