export function isPreprod() {
  let _isPreprod = JSON.parse(localStorage.getItem('isPreprod'));

  if (_isPreprod !== null) {
    return _isPreprod;
  } else if ('location' in window && location.hostname === 'preprod.altereco.insite.coop') {
    _isPreprod = true;
  } else {
    _isPreprod = false;
  }

  localStorage.setItem('isPreprod', JSON.stringify(_isPreprod));

  return _isPreprod;
}
