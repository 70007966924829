import $ from 'jquery';

export default () => {
  var activeClass = 'is-active';

  $('.js-search-trigger').on('click', function(e) {
    $('#header-search').toggleClass(activeClass);
    e.preventDefault();
  });

  $('.js-search-trigger').blur(function() {
    $('#header-search').removeClass(activeClass);
  });

  $('#main-search').focus(function() {
    $('#header-search').toggleClass(activeClass);
  });

  $(document).click(function(e) {
    if ($(e.target).closest('#header-search').length === 0) {
      $('#header-search').removeClass(activeClass);
    }
  });
};
