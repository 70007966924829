import $ from 'jquery';

const $window = $(window);

export default () => {
  let savedScroll = 0;

  $('[data-custom-modal-active="auto"]').each(function() {
    $('body').addClass('custom-modal-active');
    $(this).addClass('is-active');
  });

  $('[data-custom-modal]').on('click', function(e) {
    let modal = $(this).data('custom-modal');

    savedScroll = $window.scrollTop();

    e.preventDefault();
    $('body').addClass('custom-modal-active');
    $(modal).addClass('is-active');
  });

  $('.js-custom-modal .js-close').on('click', function(e) {
    e.preventDefault();
    $(this).parents('.js-custom-modal').removeClass('is-active');
    $('body').removeClass('custom-modal-active');

    $window.scrollTop(savedScroll);
  });
};
