/* global Drupal, dataLayer */

import $ from "jquery";

export default function () {
  window.dataLayer = window.dataLayer || [];

  if (typeof Drupal.settings.dataLayer_adds !== 'undefined') {
    dataLayer.push(Drupal.settings.dataLayer_adds);
  }

  $('a.most-read-link').on('click', function (e) {
    // @see
    // https://stackoverflow.com/questions/60031918/a-href-click-and-onclick-at-the-same-time
    e.preventDefault();
    window.dataLayer.push({
      'event': 'most_read_click',
    });
    const url = $(this).attr('href');
    setTimeout(function () {
      location.href = url;
    }, 100);
  });
}
