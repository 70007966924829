import $ from 'jquery';

const className = '.js-g-opt';

export default () => {
  // Sur document parce que le bandeau peut apparaitre plus tard.
  $(document).on('click', `${className}-close`, function(e) {
    e.preventDefault();
    $(this).closest(className).addClass('is-hidden');
  });
};
