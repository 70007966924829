import $ from 'jquery';

export default () => {
  const $sparklines = $('[data-ae-sparkline]');

  if ($sparklines.length > 0) {
    $.getScript('https://data.alternatives-economiques.fr/assets/js/altereco.charts.js', function() {
      $sparklines.each(function(index, elem) {
        const $elem = $(elem);
        const graphType = $elem.data('aeSparklineType');
        const data = JSON.parse($elem.find('[data-ae-sparkline-data]').html());
        const graphContainer = $elem.find('[data-ae-sparkline-target]');

        graphContainer.addClass('c-indicator__graph');

        var chart = new Highcharts.Chart({
          chart: {
            height: 55,
            type: graphType === 'line' ? 'line' : 'column',
            renderTo: graphContainer[0],
            margin: [10, 10, 10, 10]
          },

          yAxis: {
            visible: false
          },

          xAxis: {
            visible: false
          },

          legend: {
            enabled: false,
          },

          tooltip: {
            enabled: false
          },

          plotOptions: {
            series: {
              color: '#e6d600',
              borderWidth: 0
            }
          },

          series: [{
            name: '',
            data: data,
            lineWidth: 3,
            marker: {
              enabled: false
            }
          }]
        });
      });
    });
  }

  const $indicators = $('[data-ae-indicators]');

  if ($indicators.length > 0) {
    $.getScript('https://cdnjs.cloudflare.com/ajax/libs/fuse.js/3.2.0/fuse.min.js', function() {
      const $items = $indicators.find('[data-ae-indicators-item]');
      const $filter = $indicators.find('[data-ae-indicators-filter]');
      const $groups = $indicators.find('[data-ae-indicator-group]');
      const $openAll = $indicators.find('[data-ae-indicators-open-all]');
      const $closeAll = $indicators.find('[data-ae-indicators-close-all]');

      const data = [];

      $openAll.on('click', function(e) {
        e.preventDefault();
        openGroups();
      });

      $closeAll.on('click', function(e) {
        e.preventDefault();
        closeGroups(false);
      });

      $items.each(function(index, elem) {
        const $elem = $(elem);

        const title = $elem.find('.c-indicator__title').html().trim();

        data.push({
          title: title,
          id: $elem.data('aeIndicatorsItem')
        });
      });

      const fuse = new Fuse(data, {
        threshold: 0.3,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['title']
      });

      function showAllItems() {
        $items.removeClass('is-hidden');
      }

      function hideAllItems(items) {
        $items.addClass('is-hidden');
      }

      function showItems(items) {
        items.forEach(function(item) {
          const $item = $('[data-ae-indicators-item="' + item.id + '"]');

          $item.removeClass('is-hidden');

          $item.closest('[data-ae-indicator-group]').removeClass('is-hidden');
        });
      }

      function openGroups() {
        $groups.addClass('active');
      }

      function showGroups() {
        $groups.removeClass('is-hidden');
      }

      function hideGroups() {
        $groups.addClass('is-hidden');
      }

      function closeGroups(shouldOpenFirst = true) {
        $groups.removeClass('active');

        if (shouldOpenFirst) {
          $($groups[0]).addClass('active');
        }
      }

      $filter.on('input', function(e) {
        e.preventDefault();

        const query = $filter.val();

        const results = fuse.search(query);

        if (results.length === 0 && query.length === 0) {
          closeGroups();
          showGroups();
          showAllItems();
        } else if (results.length === 0 && query.length > 0) {
          openGroups();
          showGroups();
          hideAllItems();
        } else {
          openGroups();
          hideGroups();
          hideAllItems();
          showItems(results);
        }
      });
    });
  }

  $('[data-ae-indicator-group]').on('click', '[data-ae-indicator-group-trigger]', function(e) {
    $(this).closest('[data-ae-indicator-group]').toggleClass('active');
  });

};
