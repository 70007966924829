import $ from 'jquery';

export default () => {
  var navTrigger = $('#navigation-trigger');
  var activeClass = 'navigation-open';
  var modalActiveClass = 'modal-open';
  var navDropdown = $('.js-dropdown');
  var navListLink = $('.js-focus a');

  function openNav() {
    navDropdown.parents('li').removeClass('is-active');
    $('body').addClass(activeClass);
    navTrigger.addClass('active');
  }

  function closeNav() {
    if (!$('body').hasClass(modalActiveClass)) {
      $('body' + '.' + activeClass).removeClass(activeClass);
      navTrigger.removeClass('active');
      $('.js-focus li').removeClass('is-active');
    }
  }

  function focusOutNav(event) {
    if ($(event.target).closest('.c-header__navigation').length === 0) {
      closeNav();
    }
  }

  navTrigger.on('click', function(e) {
    if ($(this).hasClass('active')) {
      closeNav();
      e.preventDefault();
    } else {
      openNav();
      e.preventDefault();
    }
  });

  navTrigger.on('focus', function() {
    setTimeout(focusMenu, 100);
  });

  navDropdown.on('click', function(e) {
    $(this).parent('li').toggleClass('is-active');
    e.preventDefault();
  });

  navListLink.on('focus', function() {
    $(this).parent('li').addClass('is-active');
    $(this).parent('li').siblings().removeClass('is-active');
  });

  $('body').click(function(e) {
    focusOutNav(e);
  });

  $('*').focus(function(e) {
    focusOutNav(e);
  });

  // Menu navigation by tabs

  var isClick;

  $(document)
    .bind('click', function() { isClick = true; })
    .bind('keypress', function() { isClick = false; });

  var focusMenu = function() {
    if (!isClick) {
      $('body').addClass(activeClass);
    }
  };
};
