/* global google, Drupal*/

/** @var {object} Drupal.settings.aepluspaywall */

/**
 * @param {string} result.credential
 */
function onSignin(result) {
  window.location = Drupal.settings.basePath + 'google_signin/' + result.credential + '?' + jQuery.param({destination: window.location.pathname});
}

export default () => {
  // On attend que Google soit chargé. Ceci permet de charger la lib en async
  // tout en étant certain que la variable google sera définie au moment où
  // on l'appelle.
  // @see
  // https://developers.google.com/identity/gsi/web/reference/js-reference?hl=fr
  window.addEventListener('load', function () {
    /** @var {string} Drupal.settings.aepluspaywall.gauth_client_id */
    google.accounts.id.initialize({
      client_id: Drupal.settings.aepluspaywall.gauth_client_id,
      callback: onSignin
    });

    /** @var {function} google.accounts.id.renderButton */
    google.accounts.id.renderButton(
      document.getElementById('google-signin'),
      {width: 150, size: 'medium', text: 'signin'}
    );
  });
};
