import $ from 'jquery';

var CONSTANTS = {
  attrName: 'data-ae-twitter',
  popupWidth: 600,
  popupHeight: 450,
  popupUrl: 'https://twitter.com/intent/tweet?'
};

var TwitterShare = {
  create: function() {
    var instance = $.extend({}, this.prototype);

    this.init.apply(instance, arguments);

    return instance;
  },

  init: function(elem) {
    this.$elem = $(elem);

    this.urlParams = {
      text: this.$elem.attr(CONSTANTS.attrName + '-text'),
      via: this.$elem.attr(CONSTANTS.attrName + '-via'),
      url: this.$elem.attr(CONSTANTS.attrName + '-url'),
      hashtags: this.$elem.attr(CONSTANTS.attrName + '-hashtags')
    };

    this.$elem.on('click', function(e) {
      e.preventDefault();

      this.openPopup();
    }.bind(this));
  },

  prototype: {
    openPopup: function() {
      var windowId = CONSTANTS.attrName;
      var url = CONSTANTS.popupUrl + $.param(this.urlParams);

      var top = 0;
      var left = Math.round(screen.width / 2 - CONSTANTS.popupWidth / 2);

      if (screen.height > CONSTANTS.popupHeight) {
        top = Math.round(screen.height / 3 - CONSTANTS.popupHeight / 2);
      }

      var options = 'left=' + left +
          ',top=' + top +
          ',width=' + CONSTANTS.popupWidth +
          ',height=' + CONSTANTS.popupHeight +
          ',personalbar=0,toolbar=0,scrollbars=1,resizable=1';

      var win = window.open(url, windowId, options);

      if (!win) {
        location.href = url;

        return null;
      }

      win.focus();

      return win;
    }
  }
};

export default () => {
  const $tweetShares = $('[data-ae-twitter]');

  $tweetShares.each(function(index, elem) {
    TwitterShare.create(elem);
  });

  const $tweets = $('.twitter-tweet');

  if ($tweets.length > 0) {
    $.getScript('https://platform.twitter.com/widgets.js');
  }
};
