import $ from 'jquery';

export default () => {
  const figure = '.o-page__figure';
  const $figureTrigger = $('.js-article-caption');

  $figureTrigger.on('click', function(e) {
    $(this).parent(figure).toggleClass('is-active');

    if ($(this).parent(figure).hasClass('is-active')) {
      $figureTrigger.text('X');
    } else {
      $figureTrigger.text('?');
    }
    e.preventDefault();
  });
};
