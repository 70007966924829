/* global mixpanel */
import $ from 'jquery';
import * as _ from '../_';
import {enableNativeShare} from './copy';

function handleScroll($socialShare, $socialShareList, $articleNewsGrid) {
  var listSize = handleScroll.listSize = handleScroll.listSize || ($socialShareList[0].offsetHeight + $socialShareList[0].offsetTop);
  //var stickyWrapperOffset = handleScroll.stickyWrapperOffset = handleScroll.stickyWrapperOffset || $('.sticky-wrapper').offset().top;

  var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var socialShareOffset = $socialShare.offset().top;
  var distanceFromTop = socialShareOffset - currentScrollTop;

  if (distanceFromTop <= 0) {
    $socialShare.addClass('is-sticky');
  } else {
    $socialShare.removeClass('is-sticky');

    return false;
  }

  var articleNewsGridOffset = $articleNewsGrid.offset().top;
  var distanceFromBottom = articleNewsGridOffset - currentScrollTop - listSize;

  if (distanceFromBottom <= 0) {
    $socialShareList.css('position', 'absolute');
    $socialShareList.css('top', articleNewsGridOffset );
    $socialShareList.css('transform', 'translateY(-100%)');
  } else {
    $socialShareList.css('position', '');
    $socialShareList.css('top', '');
    $socialShareList.css('transform', '');
  }
}

export default () => {
  var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  var $socialShare = $('#social-share');

  if (!$socialShare.length) {
    return false;
  }

  var $socialShareList = $socialShare.find('.c-social');

  if (width > 768) {
    const $articleNewsGrid = $('#article-news-grid');

    if ($articleNewsGrid.length > 0) {
      window.addEventListener('scroll', _.throttle(handleScroll.bind(this, $socialShare, $socialShareList, $articleNewsGrid), 100));

      handleScroll($socialShare, $socialShareList, $articleNewsGrid);
    }
  }

  // Share more
  var $jsSocialMore = $('.js-social-more');

  if (enableNativeShare()) {
    $jsSocialMore.addClass('copy-me');
  }
  else {
    $jsSocialMore.on('click', function(e) {
      if ($(this).hasClass('is-active')) {
        $jsSocialMore.removeClass('is-active');
        $(this).removeClass('is-active');
      } else {
        $jsSocialMore.removeClass('is-active');
        $(this).addClass('is-active');
      }
      e.preventDefault();
    });
  }

  $(document).click(function(e) {
    if ($(e.target).closest('.c-social__item').length === 0) {
      $jsSocialMore.removeClass('is-active');
    }
  });

  $('.c-social .c-social__item > a').on('click', function(e) {
    window.dataLayer = window.dataLayer || [];

    const medium = $(e.target).data('share-medium');
    if (medium) {
      window.dataLayer.push({
        eventCategory: 'navigation',
        eventAction: 'sharing',
        eventLabel: medium
      });

      mixpanel_track('share', Object.assign({'social network':medium}, Drupal.settings.ae_mixpanel_node_view));
    }
  });

  $('#give-article__content li.c-social__item > a').on('click', function(e) {
    window.dataLayer = window.dataLayer || [];
    const medium = $(e.currentTarget).data('share-medium');
    if (medium) {
      window.dataLayer.push({
        event: 'gift',
        gift_medium: medium
      });
      // On tracke seulement si l'internaute est identifié par son email.
      if (/^.+@.+\..+$/.test(mixpanel.get_distinct_id())) {
        mixpanel_track('gift', Object.assign({'gift_medium': medium}, Drupal.settings.ae_mixpanel_node_view));
      }
    }
  });

  // Mobile
  $socialShare.on('click', '[data-social-mobile]', function(e) {
    e.preventDefault();

    $socialShareList.toggleClass('is-active');
  });
};

